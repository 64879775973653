import  React from "react";
import { ConfigProvider } from "antd";

// import 'antd/dist/antd.css';

// Import Library CSS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "react-toastify/dist/ReactToastify.css";
// Import Ant Design CSS
// import 'antd/dist/antd.css';
// import './src/styles/custom-antd.less';
// import 'antd/lib/style/index.less';

import "./src/styles/global.scss";
import theme from "./src/styles/theme";

export const wrapRootElement = ({ element }) => (
  <ConfigProvider theme={theme}>{element}</ConfigProvider>
);
